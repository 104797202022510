#header{
    font-size:106px;
}
#subheader{
    width:80%;
    line-height: 2;
}
#smallScreenImage{
    display:none;
}
#smallScreenImageLarge{
    display:block;
}
#sliderText{
    font-size: 54px;
}
#sliderComenter{
    font-size: 24px;

}
#sectionTestimonial{
    color:white;
    font-size: 64px;
}
#sectionFooterList{
    color:#8a909a;
}
#testimonials{
padding:60px;
}
#companiessectionMd{
    display:flex
}
#companiessectionMdSmall{
    display:none
}
#containedButton{
    width: 250px;
    color:white;
    background-color: #007aff;
    border-radius:50px;
    font-size:19px;
}
#smallScreenHero{
    height:100vh;
}
#ImageHide{
    display:block;
    width:100%
}
#cardHeight{
    width:70%
}
#footernav{
    display: flex;
     justify-content: center;
      padding: 3%
}
#headerFooter{
    margin-right: 60%
}
@media screen and (max-width: 600px) {
    #headerFooter{
        margin-right: 0%
    }
 
    #cardHeight{
        width:100%
    }
    #sectionTestimonial{
        font-size: 34px;
        padding-block:20%
    }
    #header{
        font-size:56px;
    }
    #sliderText{
        font-size: 18px;
    }
    #sliderComenter{
        font-size: 12px;
    
    }
    #subheader{
        width:100%;
    line-height: 1.5;

    }
    #smallScreenHero{
        padding:15px;
        height:100%
    }
    #smallScreenImage{
        display:block;
    }
    #smallScreenImageLarge{
        display:none;
    }
    #testimonials{
        padding:10px;
        }
       
        #containedButton{
            width: 200px;
        }
        #companiessectionMd{
            display:none !important
        }
        #companiessectionMdSmall{
            display:block
        }
        #ImageHide{
            width:200px
        }
            
}