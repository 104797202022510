#header1{
    font-size:66px;
}
#subheader1{
    width:80%;
    line-height: 2;
}
#smallScreenImage{
    display:none;
}
#smallScreenImageLarge{
    display:block;
}
#smallScreenHero1{
    padding-block:4% 
}
#companies:hover{
    filter: saturate(100%);
    opacity: 1;
    cursor: pointer;
}
#productHeader{
    font-size: 54px;
}
#featureIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
}
@media screen and (max-width: 600px) {
    #featureIcon{
    width: 150px;
    height: 150px;
}
    #productHeader{
        font-size: 34px;
    }
    #header1{
        font-size:46px;
    }
    #smallScreenHero1{
        padding:6%;
    }
    #subheader1{
        width:100%;
    line-height: 1.5;

    }
    #smallScreenHero{
        padding:15px;
    }
    #smallScreenImage{
        display:block;
    }
    #smallScreenImageLarge{
        display:none;
    }
}